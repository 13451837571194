:root {
    --black: #000000;
}

body {
    background-color: black !important;
    margin: 0;
    min-height: 100vh !important;
    font-family: 'Helvetica Neue', -apple-system, 'Open Sans', sans-serif;
    @media only screen and (max-width: 767px) {
       
     }
}

.bg-lighter {
    background-color: #DEDEDE;
}

.text-lighter {
    color: #777777;
}

.navbar {
    font-size: 0.9rem;
    font-weight: 600;
    .nav-link {
        color: #EFEFEF !important;
    }
    .dropdown-item {
        font-size: 0.9rem;
        font-weight: 600;
    }
}

.custom-dropdown {
    display: inline;
    .nav-link {
        display: inline;
        padding-left: 0;
    }
    .dropdown-menu {
        top:10px !important;
        min-width: 250px;
    }
    .dropdown-toggle::after {
        display: none;
    }
    .form-check {
        margin-bottom: 0.8rem;
        .form-check-label {
            color: #484848;
            font-weight: 600;
            line-height: 1.2;
        }
        .form-check-input {
            border: #484848;
        }
        
        &:first-child {
            margin-top: 0.8rem;
        }
    }
    .fixed-part {
        overflow: scroll;
        overflow: -moz-scrollbars-vertical; 
        max-height: 200px;
        box-shadow: inset 0px -10px 10px -4px rgba(0,0,0,0.1);
    }
}

.bg-black {
    //background: var(--black);
    @media only screen and (min-width: 767px) {
      //  background: slategray;
      }
}

.link {
    &:hover {
        text-decoration: none;
    }
}

.program-header {
    .logo {
        max-width: 200px;
    }
    .certification-text {
        font-size: 14px;
    }
}

.program-content-web {
    p {
        margin: 0px !important;
        word-wrap: break-word !important;
        font-size: 18px !important;
        font-weight: 400 !important;
        line-height: 1.6666666666666667em !important;
        color: #484848;
        margin-bottom: 2.5rem !important;
    }
    ol {
        list-style: none;
        counter-reset: my-awesome-counter;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        li {
            counter-increment: my-awesome-counter;
            display: flex;
            width: 100%;
            &:before {
                content: counter(my-awesome-counter) ".";
                font-weight: bold;
                font-size: 3.5rem;
                margin-right: 1rem;
                margin-left: -2.7rem;
                @media only screen and (max-width: 767px) {
                    margin-left: -1rem;
                } 
                line-height: 1;
                color: #DDDDDD;
              }
              p {
                  clear: both;
              }
          }
      }
}

.program-structure {
    p {
        border-top: 2px dashed;
        border-color: grey !important;
        margin:0 !important; 
        padding: 30px !important;
        counter-increment: section;
        position: relative !important;
        color: white !important;

      &:nth-child(even) {
        border-left: 2px dashed;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        margin-right: 30px !important; 
        padding-right: 0 !important;
        padding-left: 100 !important;
        .livemarker {
            content: "LIVE";
            font-size: 60%;
            right: 100%; 
            margin-right: -20px;
            position: absolute;
            border-radius: 50%;
            padding: 4px;
            height: 40px;
            width: 40px;
            background-color: black;
            border: 2px dashed grey;
            text-align:center;
            color: white;
            &:nth-child(2) {
                margin-top: 80px;
            }
        }
        // &:before {
        //     content: counter(section);
        //     right: 100%; 
        //     margin-right: -20px;
        //     position: absolute;
        //     border-radius: 50%;
        //     padding: 10px;
        //     height: 50px;
        //     width: 50px;
        //     background-color: blue;
        //     text-align:center;
        //     color: white;
        //     font-size: 110%;
        // }
      }
      
      &:nth-child(odd) {
        border-right: 2px dashed;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        margin-left: 30px !important; 
        padding-left: 0px !important;
        .livemarker {
            font-size: 60%;
            left: 100%; 
            margin-left: -20px;
            position: absolute;
            border-radius: 50%;
            color: white;
            padding: 4px;
            height: 40px;
            width: 40px;
            background-color: black;
            border: 2px dashed grey;
            text-align:center;
            &:nth-child(2) {
                margin-top: 60px;
            }
        }
        // &:before {
        //     content: counter(section);
        //     left: 100%; 
        //     margin-left: -20px;
        //     position: absolute;
        //     border-radius: 50%;
        //     padding: 10px;
        //     height: 50px;
        //     width: 50px;
        //     background-color: blue;
        //     text-align:center;
        //     color: white;
        //     font-size: 110%;
        // }
      }
      
      &:first-child {
        border-top: 0;
        border-top-right-radius:0;
        border-top-left-radius:0;
        padding-top: 0 !important;
        .livemarker {
            &:first-child {
                margin-top: 0px;
            }
            &:nth-child(2) {
                margin-top: 60px;
            }
        }
      }
      
      &:last-child {
        border-bottom-right-radius:0;
        border-bottom-left-radius:0;
        padding-bottom: 0 !important;
        .livemarker {
            bottom: 0;
            &:first-child {
                margin-bottom: 0px;
            }
            &:nth-child(2) {
                margin-bottom: 60px;
            }
        }
      }
    }  
}

.flextry {
    .flex-container {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
      }
      
      .flex-container > div {
          position: relative;
        background-color: black;
        flex-grow: 1;
        margin: 5px;
        text-align: center;
        background-size: cover;
        border-radius: 4px;
        min-height: 325px;
        max-height: 350px;
        display: flex;
        flex-direction: column;
        .text-over {
            min-height: 325px;
        }
        h5 {
            text-align: left !important;
            color: #FFFFFF;
        }
      }
      .flex-container > div > div {
        background-color: black;
        width: 100%;
        height: 100%;
        margin-bottom: 10px;
        text-align: center;
        line-height: 75px;
        font-size: 30px;
        border-radius: 4px;
        background-size: cover;
        background-position: center center;
        &:last-child {
            margin-bottom: 0px;
        }
      }
      #MainBanner {
        flex-grow: 1;
        position: relative;
        overflow: hidden;
        background-color: black;
        max-width: 240px;
        border-radius: 4px;
        video {
            top: 0px;
            left: 0px;
            min-width: 100%;
            min-height: 100%;            
        }
    }
}

.pricing-bar {
    position: fixed;
    bottom: 0;
    background: black;
    color: white;
    z-index: 100;
    padding-top: 20px;
    box-shadow: 2px -8px 16px 4px rgba(0,0,0,0.15);
    line-height: 1;
}

.header {
    .featured-image {
        min-height: 325px;
        background-size: cover;
        border-radius: 4px;
        @media only screen and (max-width: 767px) {
            min-height: 175px;
        }
    }
}

.category-block {

    .featured-image {
        min-height: 250px;
        background-size: cover;
        border-radius: 4px;
        @media only screen and (max-width: 767px) {
            min-height: 175px;
        }
    }

    .border-left {
        @media only screen and (max-width: 767px) {
            border-left: none !important;
        }  
    }
    .author {
        font-size: 0.8rem;
    }
}

.dark-background {
    .border-bottom {
        border-bottom-color: grey !important;
    } 
    .border-left {
        border-left-color: grey !important;     
    }
}

.post-content {
    p {
        margin: 0px !important;
        word-wrap: break-word !important;
        font-size: 18px !important;
        font-weight: 400 !important;
        line-height: 1.6666666666666667em !important;
        margin-bottom: 1.5rem !important;
    }
    .blockquote {
        border-left: 2px solid #484848;
        padding-left: 20px;
        margin-bottom: 1.5rem;
        p {
            font-size: 1.5rem !important;
            margin-bottom: 0px !important;
        }
    }
    .embed-responsive {
        margin-left: -3.5rem;
        width: calc(100% + 4.5rem);
        margin-bottom: 1.5rem;
    }   
    ol {
        list-style: none;
        counter-reset: my-awesome-counter;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        li {
            counter-increment: my-awesome-counter;
            display: flex;
            width: 100%;
            &:before {
                content: counter(my-awesome-counter) ".";
                font-weight: bold;
                font-size: 3.5rem;
                margin-right: 1rem;
                margin-left: -2.7rem;
                @media only screen and (max-width: 767px) {
                    margin-left: -1rem;
                } 
                line-height: 1;
                color: #DDDDDD;
              }
              p {
                  clear: both;
              }
          }
      }
}

.link-card {
    cursor: pointer;
    .meta-image {
        background-size: cover;
    }
    .card-text {
        margin-bottom: 0.1rem !important;
    }
    .url {
        font-size: 0.7rem !important;
        margin-bottom: 0.1rem !important;
        opacity: 0.7;
    }
    .title {
        font-size: 1.1rem !important;
        font-weight: 600 !important;
        line-height: 1.2 !important;
        margin-bottom: 0.5rem !important;
    }
    .meta-description {
        font-size: 0.8rem !important;
        line-height: 1.2 !important;
        margin-bottom: 0.1rem !important;
        opacity: 0.7;
    }
}

.consult-search {
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: red;
        opacity: 1; /* Firefox */
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: red;
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
        color: red;
      }
}